import React from 'react';
import Configs from 'Configuration';
import Paper from '@material-ui/core/Paper';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'semantic-ui-css/semantic.min.css'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import YouTube from 'react-youtube';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';




const ResponsiveReactGridLayout = WidthProvider(Responsive);
const opts = {
  height: '280',
  width: '450',
  playerVars: {
    autoplay: 0,
  },
};


const useStyles = makeStyles =>({
  root: {
    maxWidth: false,
    height:'20REM',
  },
  media: {
    height: '10REM',
  },
  paper: { minWidth: "1000px" },
  
});


class Resources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      displayGuidelines:null,
    };

  }
  handleCloseDialog = () =>{
    this.setState({openDialog:false});
  }
  handleCardClick = (searchKey) => {
    console.log ('2',searchKey)
    let dialogBody = <div>Not Loaded</div>
    switch(searchKey) {
      case 'pro1':
        dialogBody =                         
        <List>
          <ListItem>
            <span>
            <b>Therapeutic Guidelines</b>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.tg.org.au">Therapeutic Guidelines Website (www.tg.org.au)</a></li>
            </span>
            </ListItem>
            <ListItem>
              <span>
                <b>Australian Medicines Handbook </b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://amhonline.amh.net.au">Australian Medicines Handbook Link (amhonline.amh.net.au)</a></li>
              </span>
            </ListItem>
            <ListItem>
            <span>
              <b>Health Pathways Melbourne</b>
              <li><a target="_blank" rel="noopener noreferrer" href="https://melbourne.healthpathways.org.au">Link to Health Pathways Melbourne (username: connected password: healthcare) </a></li>
            </span>
          </ListItem>
        </List>
        break;
      case 'pro2':
        dialogBody =                         
        <List>
          <ListItem>
            <span>
            <b>KHA</b>
              <li><a target="_blank" rel="noopener noreferrer" href="https://assets.kidney.org.au/resources/KHA-CKD-Handbook-5th-Ed-July2024.pdf">Chronic Kidney Disease Management Handbook</a></li>
            </span>
          </ListItem>
          <ListItem>
              <span>
                  <b>KDIGO</b>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://kdigo.org/wp-content/uploads/2023/03/KDIGO-2022-Diabetes-Guideline-Annals-Synopsis-Final.pdf">KDIGO 2022 Diabetes Management in Chronic Kidney Disease Synopsis</a></li>
              </span>
            </ListItem>
            <ListItem>
              <span>
                  <b>Australian Prescriber</b>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://australianprescriber.tg.org.au/articles/sodium-glucose-co-transporter-2-inhibitors-beyond-diabetes.html">SGL2Ti Beyond Diabetes</a></li>
              </span>
            </ListItem>
        </List>
        break;
      case 'pat1':
        dialogBody =
        <List>
          <ListItem>
            <span>
              <b>KHA</b> 
              <li><a target="_blank" rel="noopener noreferrer" href="https://kidney.org.au/resources/detecting-managing-ckd-resource-library">Detecting and Managing CKD Patient Resource</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://kidney.org.au/resources/newly-diagnosed-with-ckd-resource-library">Newly Diagnosed with CKD Resource Library</a></li>
            </span>
          </ListItem>
        </List>
        break;
        case 'pro3':
          dialogBody =                         
          <List>
            <ListItem>
            <span>
              <b>RACGP</b>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/preventive-activities-in-general-practice/what-s-new-in-the-10th-ed-red-book">The Red Book</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/supporting-smoking-cessation">Supporting Smoking Cessation</a></li>
            </span>
            </ListItem>
          </List>
          break;        
        case 'pro4':

          dialogBody =                         
          <List>
            <ListItem>
              <span><b>Cardiovascular Risk</b>
              <li>Heart Foundation</li>
              {/*All links to heart foundation are no longer working and being directed to front page */}
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.cvdcheck.org.au/overview">Australian Guideline for Assessing and Managing Cardiovascular Disease Risk</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.cvdcheck.org.au/">Australian Cardiovascular Disease Risk Calculator (AusCVDRisk)</a></li>
              {/*<li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/images/uploads/publications/Key_Messages_for_GPs_and_Nurses.pdf">Summary guideline (Key Messages for GPs and Nurses)</a></li>*/}
              </span>
            </ListItem>
            <ListItem>
            <span><b>Hypertension</b>
              <li>Heart Foundation</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://assets.contentstack.io/v3/assets/blt8a393bb3b76c0ede/bltbf3d36e10b48f01f/65b0963ea933e532ae0286de/01_Hypertension-guideline-2016_WEB.pdf">Guideline for the Diagnosis and Management of Hypertension in Adults - 2016</a></li>
                  
                  </span>
            </ListItem>
            <ListItem>
              <span><b>Heart Failure</b>
                  <li>Heart Foundation</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/for-professionals/heart-failure-clinical-guidelines">Guidelines for the Prevention, Detection, and Management of Heart Failure in Australia 2018</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/for-professionals/heart-failure-tools-and-resources">For professionals: Heart Failure Tools and Resources</a></li>
              </span>
            </ListItem>
            <ListItem>
            <span>
                  <li>Peer reviewed literature</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://assets.contentstack.io/v3/assets/blt8a393bb3b76c0ede/blt76ee8ebc52f8ca2d/659ddfd61f2d0faefdacfdee/Medicine_today_-_HF_-_permission_granted_July_2019.pdf">Heart Failure Guidelines A Concise Summary for the GP</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://bmcpublichealth.biomedcentral.com/articles/10.1186/s12889-020-08781-8">The Shape Study</a></li>
              </span>
            </ListItem>
            <ListItem>
              <span><b>Lipid Management</b>
                  <li>NPS</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.nps.org.au/professionals/managing-lipids/statin-associated-muscle-symptoms-sams#:~:text=Statins%20have%20been%20associated%20with,of%20harm%20from%20a%20treatment.&text=For%20muscle%2Drelated%20adverse%20effects,(7%25%20to%2029%25).">SAMS Tool</a></li>
                  <li>Heart Foundation</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://assets.contentstack.io/v3/assets/blt8a393bb3b76c0ede/blt64cda689a04c618d/65960d4fbe5d26a4f565a12e/Cholesterol-Management-infographic_V-2.pdf">Practical Guide to Pharmacological Lipid Management</a></li>
                  <li>Stroke Foundation</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://app.magicapp.org/#/guideline/8L0RME/section/nyw7Vj">Living Clinical Guidelines for Stroke Management - Cholesterol Lowering Therapy</a></li>
              </span>
            </ListItem>
            {/* <ListItem>
              <span>
                  <li>Heart Foundation</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://assets.contentstack.io/v3/assets/blt8a393bb3b76c0ede/blt64cda689a04c618d/65960d4fbe5d26a4f565a12e/Cholesterol-Management-infographic_V-2.pdf">Practical Guide to Pharmacological Lipid Management</a></li>
              </span>
            </ListItem> */}
            {/* <ListItem>
              <span>
                  <li>Stroke Foundation</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://app.magicapp.org/#/guideline/8L0RME/section/nyw7Vj">Living Clinical Guidelines for Stroke Management - Cholesterol Lowering Therapy</a></li>
              </span>
            </ListItem> */}
            <ListItem>
              <span><b>Stroke</b>
                  <li>Stroke Foundation</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://app.magicapp.org/#/guideline/8L0RME/section/j1qQXj">Living Clinical Guidelines for Stroke-Antiplatelet Therapy</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://app.magicapp.org/#/guideline/8L0RME/section/EgV9pn">Living Clinical Guidelines for Stroke-Anticoagulant Therapy</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://app.magicapp.org/#/guideline/8L0RME/section/nyw7Vj">Living Clinical Guidelines for Stroke Management - Cholesterol Lowering Therapy</a></li>
              </span>
            </ListItem>
            <ListItem>
              <span><b>Atrial Fibrillation</b>
                  <li>Heart Foundation</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartlungcirc.org/action/showPdf?pii=S1443-9506%2818%2931778-5">Clinical Guidelines for Diagnosis and Management of AF</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://assets.contentstack.io/v3/assets/blt8a393bb3b76c0ede/blt7ad73bc1a5187974/65eaabff120a3c7d8603ebfb/Clinical_Fact_Sheet_-_Stroke_AF_(2).pdf">Stroke Prevention in Non-Valvular AF</a></li>
              </span>
            </ListItem>
            <ListItem>
              <span>
                  <li>Stroke Foundation</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://strokefoundation.org.au/About-Stroke/Prevent-Stroke/Atrial-fibrillation">Atrial Fibrillation</a></li>
              </span>
            </ListItem>
            <ListItem>
              <span><b>Acute Coronary Syndrome</b>
                  <li>Heart Foundation</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.mja.com.au/journal/2016/205/3/national-heart-foundation-australia-and-cardiac-society-australia-and-new">Management Guidelines for Acute Coronary Syndrome</a></li>
                </span>
            </ListItem>
            <ListItem>
              <span><b>Familial Hypercholesterolaemia</b>
                  <li>FH Australasia Network</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.athero.org.au/fh/">FH Australasia Network Resources</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.athero.org.au/fh/calculator/">Dutch Lipid Score Online Calculator</a></li>
              </span>
            </ListItem>
            <ListItem>
              <span>
                  <li>Peer Reviewed Articles</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www1.racgp.org.au/getattachment/53a159fd-8cb9-40c1-8a2e-69d14fb98011/Familial-hypercholesterolaemia.aspx">FH: A Guide for General Practice</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.sciencedirect.com/science/article/pii/S1443950620315055?utm_campaign=%20hhn_newsletter_jan21&utm_medium=Email&utm_source=ExactTarget#fn3">Integrated Guidance - FH in Australia</a></li>
              </span>
            </ListItem> 
          </List>
          break;
          case 'pat2':
            dialogBody =
            <List>
              <ListItem>
              <span><b>Cardiovascular risk</b>
              <li>Heart Foundation</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/your-heart/are-you-at-risk-of-heart-disease">Are you at Risk?</a></li>
              </span>
              </ListItem>   
              <ListItem>
              <span><b>Hypertension</b>
              <li>Heart Foundation</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://assets.contentstack.io/v3/assets/blt8a393bb3b76c0ede/bltb991e38e5f923469/659c8651b177906d3ad0425c/Blood-pressure-brochure.pdf">Understanding and Managing your Blood Pressure</a></li>
              </span>
              </ListItem> 
              <ListItem>
              <span><b>Heart Failure</b>
              <li>Heart Foundation</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/your-heart/heart-failure">What is Heart Failure?</a></li>
              </span>
              </ListItem> 
              <ListItem>
              <span><b>Lipid Management</b>
              <li>Heart Foundation</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/your-heart/high-blood-cholesterol">Managing your Cholesterol Levels</a></li>
              <li>NPS MedicineWise</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.nps.org.au/consumers/statin-medicines-frequently-asked-questions#why-can-high-cholesterol-levels-be-harmful?">Statin Medicines: Frequently Asked Questions</a></li>
              </span>
              </ListItem> 
              <ListItem>
              <span><b>Stroke</b>
              <li>Stroke Foundation</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://strokefoundation.org.au/what-we-do/for-survivors-and-carers/after-stroke-factsheets/medication-after-stroke-or-tia-fact-sheet#:~:text=Antiplatelet%20medicines%20stop%20platelets%20from%20sticking%20together%2C%20forming,a%20combination%20of%20aspirin%20and%20dipyridamole%2C%20and%20clopidogrel">Medication after Stroke or TIA Factsheet</a></li>
              </span>
              </ListItem>   
              <ListItem>
              <span><b>Atrial Fibrillation</b>
              <li>Stroke Foundation</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://strokefoundation.org.au/media/kknm3mtw/living-with-atrial-fibrillation.pdf">Living with Atrial Fibrillation</a></li>
              </span>
              </ListItem> 
              <ListItem>
              <span><b>Acute Coronary Syndrome</b>
              <li>Heart Foundation</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/your-heart/heart-attack">Heart Attack</a></li>
              </span>
              </ListItem>   
              <ListItem>
              <span><b>Familial Hypercholesterolaemia</b>
              <li>Heart Foundation</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://assets.contentstack.io/v3/assets/blt8a393bb3b76c0ede/blt70ea0cb778d8e8ec/65ea93b35d7713dca638e403/familial-hypercholesterolaemia.pdf">Consumer FH resource</a></li>
              </span>
              </ListItem>  
              {/* <ListItem>
              <span><b>Heart Foundation: Absolute cardiovascular risk (including guidance on lipid management for primary prevention)</b>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/your-heart/know-your-risks">Consumer information (know your risks)</a></li>
              </span>
              </ListItem>
              <ListItem>
            <span><b>Heart Foundation: Hypercholesterolaemia and Familial hypercholesterolaemia</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/getmedia/7edd455f-1b3b-4e30-b20c-487720be68e7/familial-hypercholesterolaemia.pdf">Consumer information: Familial hypercholesterolaemia</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.heartfoundation.org.au/healthy-eating/food-and-nutrition/fats-and-cholesterol">Consumer information: Fats and cholesterol</a></li>
                </span>
            </ListItem>   */}
            </List>
            break;
          case 'pro5':
            dialogBody =
            <List>
              <ListItem>
                <span><b>RACGP</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/management-of-type-2-diabetes/summary-of-recommendations">Management of type 2 diabetes: A handbook for general practice</a></li>
                </span>
              </ListItem>
              <ListItem>
                <span><b>Living Evidence for Diabetes Consortium</b>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://app.magicapp.org/#/guideline/E5AbPE">Australian Evidence-Based Clinical Guidelines for Diabetes</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://app.magicapp.org/#/guideline/E5AbPE/section/EZ5Jln">Optimal Add-On Medication</a></li>
                </span>
              </ListItem>
          
              <ListItem>
                <span><b>Australian Diabetes Society</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.diabetessociety.com.au/guideline/australian-t2d-glycaemic-management-algorithm-june-2024/#:~:text=The%20blood%20glucose%20management%20algorithm%20for%20type%202,for%20how%20to%20incorporate%20older%20and%20newer%20agents.">Blood Glucose Treatment Algorithm for Type 2 Diabetes (plan)</a></li>
                </span>
              </ListItem>
          
              <ListItem>
                <span><b>Baker IDI Heart and Diabetes Institute</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/Final+long+form+guideline+for+the+SecondaryPreventionofCVDinType2diabetes_Feb_2016.pdf">Secondary Prevention of Cardiovascular Disease in Type 2 Diabetes</a></li>
                </span>
              </ListItem>
              <ListItem>
                <span><b>NDSS</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/about-the-ndss/ndss-forms/">Forms (Registration, Medication Changes, Blood Glucose Strip 6 Month Extension)</a></li>
                </span>
              </ListItem>
              <ListItem>
                <span><b>Diabetes Victoria</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.lifeprogram.org.au/for-health-professionals/">Life! Referral Form</a></li>
                </span>
              </ListItem>
            </List>
            
            break;
          case 'pat3':
            dialogBody=
            <List>
              <ListItem>
                <span><b>Pre Diabetes</b>
                <li>NDSS</li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-understanding-pre-diabetes.pdf">Understanding Pre Diabetes Fact Sheet</a></li>
                </span>
              </ListItem>  
              <ListItem>
                <span><b>Type 2 Diabetes</b>
                <li>NDSS</li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-understanding-type2-diabetes.pdf">Understanding Type 2 Diabetes Fact Sheet</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/wp-content/uploads/fact-sheets/fact-sheet-medications-for-type2-diabetes.pdf">Medication for Type 2 Diabetes Fact Sheet</a></li>
                </span>
              </ListItem>  
              <ListItem>
              <span><li>Mayo Clinic</li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://diabetesdecisionaid.mayoclinic.org/index">Diabetes Medication Choice Decision Conversation Aid</a></li>
                </span>
              </ListItem>  
              {/* <ListItem>
                <span><b>Diabetes Australia</b>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.diabetesaustralia.com.au/living-with-diabetes">Consumer resources: Living with diabetes</a></li>
                </span>
              </ListItem>               */}
            </List>
            break;
          case 'pro6':
            dialogBody =
            <List>
              <ListItem>
              <span><b>Medicare Benefits Scheme (MBS)</b>
                <li><a target="_blank" rel="noopener noreferrer" href="http://www.mbsonline.gov.au/internet/mbsonline/publishing.nsf/Content/Home">MBS Online</a></li>
                {/* <li><a target="_blank" rel="noopener noreferrer" href="https://www.ausdoc.com.au/sites/default/files/mbs_quick_guide_july20.pdf">MBS Quick Guide</a></li> */}
                {/* <li><a target="_blank" rel="noopener noreferrer" href="https://www.ausdoc.com.au/sites/default/files/mbs_quick_guide-jul20_telehealthupdate.pdf">COVID telehealth items</a></li> */}
                </span>
              </ListItem>
              <ListItem>
                <span>
                  <b>Pharmaceutical benefits scheme (PBS)</b>
                  <li><a target="_blank" rel="noopener noreferrer" href="http://www.pbs.gov.au/pbs/home">PBS online (www.pbs.gov.au)</a></li>
                </span>
              </ListItem>
            </List>          
            break;      
            case 'pro7':
              dialogBody =
              <List>
                <ListItem>
                  <span><b>RACGP</b>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/management-of-type-2-diabetes/summary-of-recommendations">General Practice Management of Type 2 Diabetes (Introduction)</a></li>
                  </span>
                </ListItem>
                <ListItem>
                  <span><b>ADIPS</b>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.adips.org/downloads/ADIPSConsensusGuidelinesGDM-03.05.13VersionACCEPTEDFINAL.pdf">ADIPS Consensus Guidelines for GDM</a></li>
                    </span>
                </ListItem>
                <ListItem>
                    <span><b>NDSS</b>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/wp-content/uploads/gestational-diabetes-hp-flowchart-follow-up.pdf">GP Gestational Diabetes Follow up Flowchart</a></li>
                      </span>
                </ListItem>                  
              </List>          
              break;
              case 'pro8':
                  dialogBody=
                  <List>
                    <ListItem>
                      <span><b>Asthma</b>
                      <li>National Asthma Council Australia</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.asthmahandbook.org.au/management/adults">Australian Asthma Handbook</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.nationalasthma.org.au/living-with-asthma/resources/health-professionals/charts/selecting-and-adjusting-asthma-medications">Selecting and Adjusting Asthma Medications Chart</a></li>
                      </span>
                    </ListItem>
                    <ListItem>
                      <span><b>Breathlessness</b>
                      <li>Lung Foundation Australia</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://pulmonaryrehab.com.au/patient-assessment/assessing-breathlessness/">mMRC Dyspnoea Scale</a></li>
                      <li>European Respiratory Society</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://publications.ersnet.org/content/erj/63/6/2400335">Clinical practice guideline on symptom management for adults with serious respiratory illness</a></li>
                      </span>
                    </ListItem>  
                    <ListItem>
                      <span><b>Bronchiectasis</b>
                      <li>Thoracic Society of Australia and New Zealand</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://onlinelibrary.wiley.com/doi/epdf/10.1111/resp.14479">Position statement on chronic suppurative lung disease and bronchiectasis in children, adolescents and adults in Australia and New Zealand</a></li>
                      </span>
                    </ListItem>    
                    <ListItem>
                      <span><b>COPD</b>
                      <li>National Asthma Council Australia</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.asthmahandbook.org.au/clinical-issues/copd">COPD and Asthma Overlap</a></li>
                      <li>Lung Foundation Australia</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://copdx.org.au/wp-content/uploads/2023/09/WEBSITE_COPDX-V2-70_FINAL.pdf">COPD Guidelines: The COPD-X Plan</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://lungfoundation.com.au/resources/stepwise-management-of-stable-copd/">Stepwise Management of Stable COPD factsheet</a></li>
                      </span>
                    </ListItem>  
                    <ListItem>
                      <span><b>Interstitial Lung Disease</b>
                      <li>Thoracic Society of Australia and New Zealand</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://onlinelibrary.wiley.com/doi/epdf/10.1111/resp.14656">Treatment of idiopathic pulmonary fibrosis and progressive pulmonary fibrosis: A position statement</a></li>
                      </span>
                    </ListItem>
                    <ListItem>
                      <span><b>Lung Cancer</b>
                      <li>Cancer Council  Australia</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.cancer.org.au/clinical-guidelines/lung-cancer/lung-cancer-treatment">Clinical practice guidelines for the treatment of lung cancer</a></li>
                      </span>
                    </ListItem>              
                  </List>
                  break;
                  case 'pro9':
                    dialogBody=
                    <List>
                      <ListItem>
                        <span><b>Anaemia</b>
                        <li>Future Health Today</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/AnaemiaRecommendedfollow-upforpeopleatriskofanundiagnosedcancerv3.16.7.2021.pdf">Anaemia and Risk of Cancer. Summary of Evidence</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/Causesofanaemiainadults.JPG">Table: Causes of Anaemia in Adults. Up to date 2017</a></li>
                        </span>
                      </ListItem>  
                      <ListItem>
                        <span><b>PSA</b>
                        <li>Prostate Cancer Foundation Australia</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.prostate.org.au/testing-and-diagnosis/psa-testing/">PSA testing guidelines</a></li>
                        <li>Cancer Council Australia</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.cancervic.org.au/downloads/health-professionals/optimal-care-pathways/I-PACED_prostate_cancer_resource_card_online.pdf">Prostate Cancer Resource Card</a></li>
                        </span>
                      </ListItem>    
                      <ListItem>
                        <span><b>Thrombositosis</b>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/ThrombocytosisRecommendedfollow-upforpeopleatriskofanundiagnosedcancerv4.2021.7.2021.pdf">Thrombositosis and Risk of Cancer</a></li>
                        </span>
                      </ListItem>
                    </List>
                    break;  
                  case 'pro10':
                    dialogBody=
                    <List>
                      <ListItem>
                        <span>
                        <li>WHO</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://iris.who.int/bitstream/handle/10665/344797/9789240032767-eng.pdf?sequence=1">Hearing Screening -Considerations for Implementation</a></li>
                        </span>
                      </ListItem>  
                    </List>
                  break;  
                  case 'pro11':
                    dialogBody=
                    <List>
                      <ListItem>
                        <span><b>Metablic Dysfunction Associated Fatty Liver Disease (MAFLD)</b>
                        <li>GESA</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Clinical%20Practice%20Resources/MAFLD/MAFLD%20consensus%20statement%202024.pdf">Consensus Statement: Recommendations for the Assessment of Metabolic Dysfunction-Associated Fatty Liver Disease (MAFLD) in Primary Care</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Clinical%20Practice%20Resources/MAFLD/GESA%20MAFLD%20Recommendations%20Summary.pdf">Summary of Recommendations for the Assessment of Metabolic Dysfunction-Associated Fatty Liver Disease (MAFLD) in Primary Care</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Clinical%20Practice%20Resources/Understanding%20Liver%20Tests/Understanding%20Liver%20Tests_2024_update%20(1).pdf">GP Information on Understanding Liver Tests</a></li>
                        </span>
                      </ListItem>  
                      <ListItem>
                        <span><b>Hepatitis B Liver Disease</b>
                        <li>GESA</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.gesa.org.au/resources/clinical-practice-resources/hepatitis-b-virus-hbv-consensus-statement/">National Hepatitis B Management Consensus Statement</a></li>
                        </span>
                      </ListItem>  
                    </List>
                  break;  
                  case 'pro12':
                    dialogBody=
                    <List>
                      <ListItem>
                        <span>
                        <li>Monash University</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.menopause.org.au/images/pics/ptmm/a-practitioners-toolkit-for-managing-menopause.pdf">2023 Practitioner Toolkit for Managing Menopause</a></li>
                        </span>
                      </ListItem>  
                      <ListItem>
                        <span>
                        <li>Jean Hailes</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.jeanhailes.org.au/resources/menopausal-hormone-therapy">Menopausal Hormone Therapy</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.jeanhailes.org.au/health-a-z/bone-health/osteoporosis-and-osteopaenia">Osteoporosis and Osteopaenia</a></li>
                        </span>
                      </ListItem>  
                    </List>
                  break;  
                  case 'pro13':
                    dialogBody=
                    <List>
                      <ListItem>
                      <span><b>General STI guidelines</b>
                        <li>ASHM</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://sti.guidelines.org.au/standard-asymptomatic-checkup/">Standard Asymptomatic Check-up</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://sti.guidelines.org.au/populations-and-situations/pregnant-people/">Australian STI Management Guidelines - Pregnant People</a></li>
                        </span>
                      </ListItem>  
                      <ListItem>
                        <span><b>Syphilis</b>
                        <li>ALEC</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://app.magicapp.org/#/guideline/jm83RE/section/EdrZ5m">Pregnancy Care Guidelines –Syphilis</a></li>
                        </span>
                      </ListItem>
                    </List>
                  break;  

              case 'pat4':
                dialogBody=
                <List>
                  <ListItem>
                    <span><b>NDSS</b>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.ndss.com.au/about-diabetes/gestational-diabetes/after/">Your Health after Gestational Diabetes</a></li>
                    </span>
                  </ListItem>              
                </List>
                break;         
                case 'pat5':
                dialogBody=
                <List>
                  <ListItem>
                    <span><b>Menopause</b>
                    <li>Jean Hailes</li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.jeanhailes.org.au/resources/menopausal-hormone-therapy">Menopausal Hormone Therapy</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.jeanhailes.org.au/health-a-z/bone-health/osteoporosis-and-osteopaenia">Osteoporosis and Osteopaenia</a></li>
                    </span>
                  </ListItem>              
                </List>
                break;                    
                case 'pat6':
                dialogBody=
                <List>
                  <ListItem>
                    <span><b>Syphilis</b>
                    <li>Victorian Sexual Health Network</li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.staystifree.org.au/get-the-facts/syphilis">Syphilis Factsheet</a></li>
                    <li>Department of Health and Aged Care</li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.health.gov.au/resources/collections/dont-fool-around-with-syphilis-resources">Women and Syphilis Factsheet</a></li>
                    </span>
                  </ListItem>              
                </List>
                break;       
                case 'pat7':
                dialogBody=
                <List>
                  <ListItem>
                    <span><b>Smoking</b>
                    <li>Quit</li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.quit.org.au/">Quit Resources</a></li>
                    <li>Department of Health and Aged Care</li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://strokefoundation.org.au/About-Stroke/Prevent-Stroke/Smoking">Stroke Foundation</a></li>
                    </span>
                  </ListItem>              
                </List>
                break;   
                case 'pat8':
                  dialogBody=
                  <List>
                    <ListItem>
                      <span><b>Asthma</b>
                      <li>National Asthma Council Australia</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://d8z57tiamduo7.cloudfront.net/resources/NAC011-My-Asthma-Guide-Update-2022_Web.pdf">My Asthma Guide</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://d8z57tiamduo7.cloudfront.net/resources/NAC-Asthma-Action-Plan-2023-FILLABLE-HD.pdf">Asthma Action Plan</a></li>
                      </span>
                    </ListItem>
                    <ListItem>
                      <span><b>Breathlessness</b>
                      <li>Inspire</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://inspirebreathingbetter.com/">Breathing Better</a></li>
                      </span>
                    </ListItem>  
                    <ListItem>
                      <span><b>Bronchiectasis</b>
                      <li>Lung Foundation Australia</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://lungfoundation.com.au/wp-content/uploads/2018/09/Factsheet-Bronchiectasis-Sep2018.pdf">Bronchiectasis Fact Sheet</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://lungfoundation.com.au/wp-content/uploads/2020/04/Flyer-Bronchiectasis-Action-Plan-Feb-2020.pdf">Bronchiectasis Action Plan</a></li>
                      </span>
                    </ListItem>    
                    <ListItem>
                      <span><b>COPD</b>
                      <li>Lung Foundation Australia</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://lungfoundation.com.au/resources/copd-fact-sheet/">COPD Factsheet</a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://lungfoundation.com.au/wp-content/uploads/2022/12/LFA_COPD-Action-Plan_Editable_final.pdf">COPD Action Plan</a></li>
                      </span>
                    </ListItem>  
                    <ListItem>
                      <span><b>Interstitial Lung Disease</b>
                      <li>Lung Foundation Australia</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://lungfoundation.com.au/patients-carers/living-with-a-lung-disease/pf/resources/">ILD Patient Resources</a></li>
                      </span>
                    </ListItem>
                    <ListItem>
                      <span><b>Lung Cancer</b>
                      <li>Cancer Australia</li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.canceraustralia.gov.au/sites/default/files/publications/lngc_lung_cancer_factsheet_51e64803f2b71.pdf">Lung Cancer Fact Sheet</a></li>
                      </span>
                    </ListItem>              
                  </List>
                  break;
                  case 'pat9':
                    dialogBody=
                    <List>
                      <ListItem>
                        <span><b>Anaemia</b>
                        <li>Future Health Today</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-irondeficiencyanaemia.pdf">Patient Information Sheet - Iron Deficiency Anaemia</a></li>
                        </span>
                      </ListItem>  
                      <ListItem>
                        <span><b>PSA</b>
                        <li>Future Health Today</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="">Patient Information Sheet – PSA</a></li>
                        </span>
                      </ListItem>    
                      <ListItem>
                        <span><b>Thrombositosis</b>
                        <li>Future Health Today</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/FutureHealthTodayPatientinformationsheet-raisedplateletcount.pdf">Patient Information Sheet- Raised Platelet Count</a></li>
                        </span>
                      </ListItem>
                    </List>
                    break;  
                    case 'pat10':
                      dialogBody=
                      <List>
                        <ListItem>
                          <span>
                          <li>(No resource presently)</li>
                          </span>
                        </ListItem>              
                      </List>
                      break;   
                      case 'pat11':
                        dialogBody=
                        <List>
                          <ListItem>
                            <span><b>Metablic Dysfunction Associated Fatty Liver Disease (MAFLD)</b>
                            <li>GESA</li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Fatty%20Liver/Fatty%20Liver%20Disease%202021%20final.pdf">What is Fatty Liver Disease?</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Fibroscan/FibroScan_2022_APPROVED.pdf">What is a Fibroscan?</a></li>
                            <li>IDEAL Care Trial</li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtGuidelines/IDEAL+Care+pt+resource+v1.5_FINAL_LF_approved.pdf">Liver Health Patient Information Sheet</a></li>
                            <li>Liver Foundation</li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://liver.org.au/living-well/">Lifestyle Recommendations for Living Well</a></li>
                            </span>
                          </ListItem>       
                          <ListItem>
                            <span><b>Hepatits B Liver Disease</b>
                            <li>GESA</li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Treatment%20for%20Hepatitis%20B/Treatment%20for%20Hepatitis%20B_2022_APPROVED.pdf">Treatment for Hepatitis B</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Fibroscan/FibroScan_2022_APPROVED.pdf">What is a Fibroscan?</a></li>
                            <li>Liver Foundation</li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://liver.org.au/living-well/">Lifestyle Recommendations for Living Well</a></li>
                            </span>
                          </ListItem>              
                          <ListItem>
                            <span><b>Hepatits C Liver Disease</b>
                            <li>Liver Foundation</li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://liver.org.au/your-liver/liver-diseases/hepatitis-c/">Hepatitis C</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.gesa.org.au/public/13/files/Education%20%26%20Resources/Patient%20Resources/Fibroscan/FibroScan_2022_APPROVED.pdf">What is a Fibroscan?</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://liver.org.au/living-well/">Lifestyle Recommendations for Living Well</a></li>
                            </span>
                          </ListItem>     
                        </List>
                        break;                                                                              
      default:
        // code block
    }

    this.setState({displayGuidelines: dialogBody, openDialog:true});
  }
  render() {
    const classes = useStyles();
    let guidelinesCounter = 1;
let cardheight = 8;
let cardwidth = 2;
let xwidth = 2.4;
let imageLinks = [
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/generalGuidelines.jpg', title:'General Guidelines',description:'',searchKey:'pro1'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/CKD.jpg', title:'Chronic Kidney Disease',description:'',searchKey:'pro2'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/preventivehealth1.jpg', title:'Preventative Health',description:'',searchKey:'pro3'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/CVD1.jpg', title:'Cardiovascular disease',description:'',searchKey:'pro4'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/diabetes1.jpg', title:'Type 2 Diabetes',description:'',searchKey:'pro5'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/mbs.jpg', title:'Medicare Benefits Scheme',description:'',searchKey:'pro6'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/GDMG4M.jpg', title:'Gestational Diabetes',description:'',searchKey:'pro7'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/Respiratoryconditions.jpg', title:'Respiratory conditions',description:'',searchKey:'pro8'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/CancerRisk.jpg', title:'Cancer Risk',description:'',searchKey:'pro9'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/HearingHealth.jpg', title:'Hearing Health',description:'',searchKey:'pro10'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/LiverConditions.jpg', title:'Liver Conditions ',description:'',searchKey:'pro11'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/Menopause.jpg', title:'Menopause',description:'',searchKey:'pro12'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/SexualHealth.jpg', title:'Sexual Health',description:'',searchKey:'pro13'},
                  ];
var i;
let guidelineItemsDictionary = [];
for (i = 0; i < imageLinks.length; i++) {
  let ystartingposition = 0;
  let xstartingposition = 1.4;
  let itemsPerRow = 4; 
  let xposition = (i%itemsPerRow)*(xwidth) + xstartingposition;
  let yposition = ystartingposition + (cardheight*Math.floor(i/4));
  guidelineItemsDictionary.push({ videoCounter: i, imageLink: imageLinks[i].image, xposition: xposition, yposition:yposition, title:imageLinks[i].title, 
    description:imageLinks[i].description, searchKey:imageLinks[i].searchKey });
}


let professionalGuidelineLinksCode = 
guidelineItemsDictionary.map(guidelineItem => {
  let keyGuideline = "guidelinelink" + guidelineItem.videoCounter;
  return (
    <div key={keyGuideline} style = {{ }}data-grid={{ x: guidelineItem.xposition, y: guidelineItem.yposition, w: cardwidth, h: cardheight, static: true}}>
      <Card className={classes.root} >
      <CardActionArea onClick={() => {this.handleCardClick(guidelineItem.searchKey)}}>
        <CardMedia
        style={{height:'10REM'}}
          image={guidelineItem.imageLink}
          title={guidelineItem.title}
        />          
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" >
          {guidelineItem.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {guidelineItem.description}
          </Typography>
        </CardContent>
        </CardActionArea>
      </Card>
    </div>
  )
})

let patientImageLinks = [
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/CKD.jpg', title:'Chronic Kidney Disease',description:'',searchKey:'pat1'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/CVD2.jpg', title:'Cardiovascular disease',description:'',searchKey:'pat2'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/diabetes2.jpg', title:'Type 2 Diabetes',description:'',searchKey:'pat3'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/GDMG4M.jpg', title:'Gestational Diabetes',description:'',searchKey:'pat4'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/Menopause.jpg', title:'Menopause',description:'',searchKey:'pat5'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/SexualHealth.jpg', title:'Sexual Health',description:'',searchKey:'pat6'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/preventivehealth1.jpg', title:'Preventative Health',description:'',searchKey:'pat7'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/Respiratoryconditions.jpg', title:'Respiratory conditions',description:'',searchKey:'pat8'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/CancerRisk.jpg', title:'Cancer Risk',description:'',searchKey:'pat9'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/HearingHealth.jpg', title:'Hearing Health',description:'',searchKey:'pat10'},
                  {image:'https://fhtwebpublicaccess.s3.ap-southeast-2.amazonaws.com/FhtImages/LiverConditions.jpg', title:'Liver Conditions ',description:'',searchKey:'pat11'},
                  ];
var i;
let patientGuidelineItemsDictionary = [];
for (i = 0; i < patientImageLinks.length; i++) {
  let ystartingposition = 0;
  let xstartingposition = 1.4;
  let itemsPerRow = 4; 
  let xposition = (i%itemsPerRow)*(xwidth) + xstartingposition;
  let yposition = ystartingposition + (cardheight*Math.floor(i/4));
  patientGuidelineItemsDictionary.push({ videoCounter: i, imageLink: patientImageLinks[i].image, xposition: xposition, yposition:yposition, title:patientImageLinks[i].title, 
    description:patientImageLinks[i].description, searchKey:patientImageLinks[i].searchKey });
}
let patientGuidelineLinksCode = 
patientGuidelineItemsDictionary.map(guidelineItem => {
  let keyGuideline = "guidelinelink" + guidelineItem.videoCounter;
  return (
    <div key={keyGuideline} style = {{ }}data-grid={{ x: guidelineItem.xposition, y: guidelineItem.yposition, w: cardwidth, h: cardheight, static: true}}>
      <Card className={classes.root} style={{display: 'flex'}}>
      <CardActionArea onClick={() => {this.handleCardClick(guidelineItem.searchKey)}}>
        <CardMedia
        style={{height:'10REM'}}
          image={guidelineItem.imageLink}
          title={guidelineItem.title}
        />          
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          {guidelineItem.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {guidelineItem.description}
          </Typography>
        </CardContent>
        </CardActionArea>
      </Card>
    </div>
  )
})
    return (
    <div>    
      {/*<Paper style={{paddingBottom:'3REM'}}>*/}
      <ResponsiveReactGridLayout
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={40}
          isResizable={false}
          >
        <div key="forProfessionalLinks" style = {{ }}data-grid={{ x: 0.1, y: 0, w: 12, h: 25, static: true}}>
          <div>
          <div style={{fontSize:'3REM', textAlign:'center', paddingTop:'2REM'}}>
            Resources
                    </div>
          <hr style={{width: '10%', height: '0.2REM', marginLeft: 'auto', marginRight: 'auto', backgroundImage:'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))', border: 'none'}} />                        

          <div style={{fontSize:'2REM', textAlign:'center', paddingTop:'2REM'}}>
                      <i class="circular stethoscope icon"></i>
                      For Professionals
                    </div>
          <hr style={{width: '80%', height: '0.1REM', marginLeft: 'auto', marginRight: 'auto', backgroundImage:'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))', border: 'none'}} />
            <ResponsiveReactGridLayout
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
              rowHeight={26}
              isResizable={false}
              >
                {professionalGuidelineLinksCode}
            </ResponsiveReactGridLayout>
          </div> 
        </div>
      </ResponsiveReactGridLayout>
     

      {/*<Paper style={{paddingTop:'15REM'}}>*/}
      <ResponsiveReactGridLayout
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={20}
          isResizable={false}
          >
        <div key="forPatientLinks" style = {{ }}data-grid={{ x: 0.1, y:0, w: 12, h: 20, static: true}}>
          <div>
          <div style={{fontSize:'2REM', textAlign:'center'}}>
                      <i class="circular user icon"></i>
                      For Patients
                    </div>
          <hr style={{width: '80%', height: '0.1REM', marginLeft: 'auto', marginRight: 'auto', backgroundImage:'linear-gradient(to right, rgb(39, 149, 148), rgb(72, 175, 104))', border: 'none'}} />            
            <ResponsiveReactGridLayout
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
              rowHeight={25}
              isResizable={false}
              >
                {patientGuidelineLinksCode}
            </ResponsiveReactGridLayout>
          </div> 
        </div>
      </ResponsiveReactGridLayout>


      <Dialog onClose={(e)=>this.handleCloseDialog()} aria-labelledby="simple-dialog-title" open={this.state.openDialog} 
      maxWidth='md'>
        <DialogTitle style={{textAlign:'center'}} id="simple-dialog-title"><b>Resources</b></DialogTitle>
        {this.state.displayGuidelines}
      </Dialog>
    </div>)
    };
}

export default withStyles(useStyles)(Resources);
